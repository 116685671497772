//
// Buttons
// --------------------------------------------------

.btn {
    font-family: $font-family-base;
    font-weight: 600;

    &.btn-square {
        border-radius: 0 !important;
    }

    &.btn-rounded {
        border-radius: 50px !important;
    }

    .si {
        position: relative;
        top: 1px;
    }
}

.btn.btn-hero {
    @include button-size(
        $btn-hero-padding-y,
        $btn-hero-padding-x,
        $font-size-base,
        $btn-hero-line-height,
        3px
    );
    height: auto;

    &.btn-sm {
        @include button-size(
            $btn-hero-padding-y-sm,
            $btn-hero-padding-x-sm,
            $font-size-base,
            $btn-hero-line-height-sm,
            3px
        );
    }

    &.btn-lg {
        @include button-size(
            $btn-hero-padding-y-lg,
            $btn-hero-padding-x-lg,
            $font-size-md,
            $btn-hero-line-height-lg,
            3px
        );
    }
}

.btn.btn-circle {
    @include button-size(9px, 0, $font-size-base, 1, inherit);
    min-width: 34px;
    height: 34px;
    text-align: center;
    border-radius: 50% !important;

    &.btn-sm {
        @include button-size(6px, 0, $font-size-base, 1, inherit);
        min-width: 28px;
        height: 28px;
    }

    &.btn-lg {
        @include button-size(12px, 0, $font-size-base, 1, inherit);
        min-width: 40px;
        height: 40px;
    }

    > .si {
        top: 0;
    }
}

.btn.btn-close {
    color: $colour-grey900 !important;
    font-size: 2.2rem !important;
    background: none !important;
    border: none !important;
}

.btn-secondary {
    @include button-variant($body-bg, $body-bg);
}

.btn-outline-secondary {
    @include button-outline-variant(darken($body-bg-dark, 40%));
}

.btn-alt-primary {
    @include button-alt-variant($brand-primary, $brand-lighter);
}

.btn-alt-secondary {
    @include button-alt-variant($body-color, $body-bg-dark);
}

.btn-alt-success {
    @include button-alt-variant($brand-success, $brand-success-light);
}

.btn-alt-info {
    @include button-alt-variant($brand-info, $brand-info-light);
}

.btn-alt-warning {
    @include button-alt-variant($brand-warning, $brand-warning-light);
}

.btn-alt-danger {
    @include button-alt-variant($brand-danger, $brand-danger-light);
}

// Used for buttons that adapt to light/dark header and sidebar variations
.btn-dual-secondary {
    @include button-dual-variant(
        darken($body-color, 22%),
        darken($body-bg, 10%)
    );
}

.page-header-inverse #page-header .btn-dual-secondary,
.sidebar-inverse #sidebar .btn-dual-secondary {
    @include button-dual-variant($white, $brand-darker);
}
