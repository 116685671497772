@font-face {
	font-family: 'avenirbold';
	src: url('../fonts/Avenir95Black.woff2') format('woff2'),
	url('../fonts/Avenir95Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avenirmedium';
	src: url('../fonts/Avenir45Book.woff2') format('woff2'),
	url('../fonts/Avenir45Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avenirregular';
	src: url('../fonts/Avenir35Light.woff2') format('woff2'),
	url('../fonts/Avenir35Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: $font-regular;
	color: $colour-grey900;
	font-size: 16px;
}

a {
	color: $colour-blue500;
	text-decoration: none;

	&:hover {
		color: $colour-blue500;
	}
}

.bold {
	font-family: $font-heading;
}

.sub-bold {
	font-family: $font-subheading;
}

h1, h2, .h1, .h2 {
	color: $colour-grey900;
	font-family: $font-heading;
}

h3, h4, .h3, .h4 {
	color: $colour-grey900;
	font-family: $font-subheading;
}

h5, h6, .h5, .h6 {
	color: $colour-grey900;
	font-family: $font-subheading;
}

.module-inner {
	h1, h2, h3, h4 {
		color: $colour-grey900;
	}
}

h1, .h1 {
	font-size: 24px !important;
}

h2, .h2 {
	font-size: 22px !important;
}

h3, .h3 {
	font-size: 20px !important;
}

h4, .h4 {
	font-size: 18px !important;
}

h5, .h5 {
	font-size: 16px !important;
}

h6, .h6 {
	font-size: 16px !important;
}

@include media-breakpoint-down(sm) {
    h2, .h2 {
        font-size: 20px !important;
    }
}

.font-small {
	font-size: 14px;
}

.font-smaller {
	font-size: 13px;
}

.font-medium {
	font-size: 18px;
}

.font-large {
	font-size: 20px;
}

.font-larger {
	font-size: 22px;
}

.color-lime {
	color: $colour-lime;
}

.color-spearmint {
	color: $colour-spearmint;
}

.color-indigo {
	color: $colour-grey900;
}

.color-red {
	color: $colour-red;
}

.terms h3 {
	color: $colour-lime;
}
