//
// Pagination
// --------------------------------------------------

.page-link {
    font-weight: 600;

    &:focus {
        color: $body-color-dark;
        background-color: $body-bg-dark;
        border-color: $body-bg-dark;
    }
}

.pagination-sm .page-link {
    font-size: 1rem;
    line-height: 16px;
}

.pagination-lg .page-link {
    font-size: 1.142857rem;
    line-height: 20px;
}