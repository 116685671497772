//
// Forms
// --------------------------------------------------

label {
    font-weight: 600;
}

.invalid-feedback {
    font-size: $font-size-sm;
}
