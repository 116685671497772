//
// General Forms
// --------------------------------------------------

.form-general {
    padding: 20px 15px;

	@include media-breakpoint-down(sm) {
		padding: 0px !important;
	}

	@include media-breakpoint-down(xs) {
		padding: 0px !important;
	}

    select > option {
        color: $colour-grey900 !important;
    }

	label {
		color: $colour-indigo;
    	font-size: 15px;
	}

	.btn {
		color: $colour-white;
		padding: 10px 20px;
		border-radius: 3px;
		height: calc(2.028572em + 0.8571428rem + 2px);
	}

	.btn-alt-primary {
		font-size: 16px;
		&:not([disabled]):hover {
			color: $colour-white;
			box-shadow: 0 2px 24px 0 rgba(0,0,0,.1), 0 10px 24px 0 rgba(0,0,0,.18);
			background-color: $colour-red500;
			border-color: $colour-red500;
			box-shadow: none;
		}

		&:not([disabled]):focus {
			color: $colour-white;
			box-shadow: 0 2px 24px 0 rgba(0,0,0,.1), 0 10px 24px 0 rgba(0,0,0,.18);
			background-color: $colour-red500;
			border-color: $colour-red500;
			box-shadow: none;
		}

		&:not([disabled]).focus {
			color: $colour-white;
			box-shadow: 0 2px 24px 0 rgba(0,0,0,.1), 0 10px 24px 0 rgba(0,0,0,.18);
			background-color: $colour-red500;
			border-color: $colour-red500;
			box-shadow: none;
        }

        &:not([disabled]):not(.disabled):active {
            color: $colour-white;
            background-color: $colour-red500;
			border-color: $colour-red500;
        }
}

	.form-control {
		border: 2px solid $colour-lime;
		border-radius: 3px;
		-webkit-transition: .2s cubic-bezier(.4,0,.2,1);
		transition: .2s cubic-bezier(.4,0,.2,1);
		padding: 0 10px;
        font-size: 15px;
        height: calc(2.028572em + 0.8571428rem + 2px);

		&:focus {
			border: 2px solid $colour-indigo;
		}
	}

	textarea.form-control {
		padding-top: 10px;
		height: auto !important;
	}

	.select2-container--default {
		font-size: 15px;

		.select2-selection--multiple {
			border: 2px solid $colour-lime;
			border-radius: 3px;
			-webkit-transition: .2s cubic-bezier(.4,0,.2,1);
			transition: .2s cubic-bezier(.4,0,.2,1);
            min-height: calc(2.028572em + 0.8571428rem + 2px);

			.select2-selection__choice {
				background-color: $colour-indigo;
			}
		}

		.select2-selection--single {
			border: 2px solid $colour-lime;
			border-radius: 3px;
			-webkit-transition: .2s cubic-bezier(.4,0,.2,1);
			transition: .2s cubic-bezier(.4,0,.2,1);
			line-height: calc(2.028572em + 0.8571428rem + 2px);
            height: calc(2.028572em + 0.8571428rem + 2px);

            .select2-selection__rendered, .select2-selection__arrow {
                line-height: calc(2.028572em + 0.8571428rem + 2px);
                height: calc(2.028572em + 0.8571428rem + 2px);
            }
		}

		&.select2-container--focus {
			.select2-selection--multiple {
				border: 2px solid $colour-indigo;
			}

			.select2-selection--single {
				border: 2px solid $colour-indigo;
			}
		}

		.select2-search--inline {
			.select2-search__field {
				font-family: $font-regular;
			}
		}
	}
}

.is-invalid {
	.form-control {
		border-color: $colour-red !important;
		transition: none;
	}

	.radio-flat-label {
		border: 1px solid $colour-red !important;
	}

	.css-control-indicator {
		border-color: $colour-red !important;
	}

	.select2-container--default {
		.select2-selection--multiple {
			border: 2px solid $colour-red !important;
			transition: none;
		}

		.select2-selection--single {
			border: 2px solid $colour-red !important;
			transition: none;
		}

		.select2-container--focus {
			.select2-selection--multiple {
				border: 2px solid $colour-red !important;
				transition: none;
			}

			.select2-selection--single {
				border: 2px solid $colour-red !important;
				transition: none;
			}
		}
	}
}

.select2-hidden-accessible {
    border: 0!important;
    clip: rect(0 0 0 0)!important;
    -webkit-clip-path: inset(50%)!important;
    clip-path: inset(50%)!important;
    height: 1px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 1px!important;
    white-space: nowrap!important;
}

.select2-container--default {
	.select2-results__option--highlighted[aria-selected] {
		background-color: $colour-grey50;
		color: $colour-grey900;
	}
}

.select2-results__option[aria-selected] {
	cursor: pointer;
	color: $colour-grey900;
}

.custom-form-field {
    input {
        border-radius: 10px;
        background: $colour-grey50;
        font-size: 16px;
        padding: 15px 20px;
        border: none;
        height: auto;

        &:focus {
            background: $colour-grey50 !important;
        }
    }

    label {
        color: $colour-red500;
        font-weight: normal;
    }
}
