.login-container,
.reset-password-container {

    a {
        color: $colour-red500;
    }

	.hero-static {
		-webkit-box-shadow: 0 6px 43px -8px rgba(0, 0, 0, .35);
		box-shadow: 0 6px 43px -8px rgba(0, 0, 0, .35);
	}

	&.bg-image {
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../media/login-background.webp');
	}

	.login-logo,
	.reset-password-logo {
		img {
			width: 100px;
		}
	}

	.btn-custom {
		padding: 15px 80px;
		color: $colour-white;
		font-family: $font-heading;
		font-size: 18px;
	}

	h1 {
		margin: 70px 0 20px 0;
		color: $colour-grey900;
		font-size: 22px;
		font-family: $font-regular;
		line-height: 32px;
	}

    @include media-breakpoint-down(xs) {
        h1 {
            margin-bottom: 10px;
        }
    }

	h4 {
		color: $colour-white;
		margin: 10px 0 15px 0;
		text-transform: uppercase;
		padding: 6px;
		display: inline-block;
		background-color: $colour-blue500;
		background-size: 100% 100%;
	}

	.btn-resend {
		margin-top: 12px;
	}

	.p-l-0 {
		padding-left: 0 !important;
	}

	.p-r-0 {
		padding-right: 0 !important;
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;

	&:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}
}

input:checked+.slider {
	background-color: $colour-indigo;
}

input:focus+.slider {
	box-shadow: 0 0 1px $colour-indigo;
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.alert-info {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 24px;
	border: 0 solid transparent;
	border-radius: 0.25rem;
	background-color: $colour-grey50;
	color: $colour-grey900;
}
