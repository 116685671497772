//
// Material Forms
// --------------------------------------------------

.form-material .form-control {
    padding: 1.4285714rem 1rem;
}

.form-material {
    overflow: hidden;

    &.floating > label {
        font-size: $font-size-md;
        font-weight: 700;
        cursor: text;
        z-index: 2;
        -webkit-transition: transform 0.2s ease-out, font-size 0.2s ease-out;
        -moz-transition: transform 0.2s ease-out, font-size 0.2s ease-out;
        -o-transition: transform 0.2s ease-out, font-size 0.2s ease-out;
        transition: transform 0.2s ease-out, font-size 0.2s ease-out;
        color: $colour-indigo;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .graphic {
        position: absolute;
        top: 0;
        left: 0;
        fill: none;

        &.graphic--nao {
            stroke: $colour-indigo;
            pointer-events: none;
            -webkit-transition: -webkit-transform .7s;
            -webkit-transition: -webkit-transform .7s;
            transition: -webkit-transform .7s;
            transition: transform .7s;
            transition: transform .7s,-webkit-transform .7s;
            -webkit-transition-timing-function: cubic-bezier(0,.25,.5,1);
            transition-timing-function: cubic-bezier(0,.25,.5,1);
        }
    }

    // Form controls and input addons
    > .form-control {
        padding-left: 0;
        padding-right: 0;
        border-color: transparent;
        border-radius: 0;
        background-color: transparent;
        box-shadow: 0 1px 0 $colour-indigo;
        transition: box-shadow .3s ease-out;
        border: none;

        ~ .input-group-append {
            box-shadow: 0 1px 0 $colour-indigo;
            transition: box-shadow .3s ease-out;
        }

        &:focus {
            background-color: transparent;
            border-color: transparent;
            box-shadow: 0 2px 0 $colour-indigo;

            + label {
                color: $colour-indigo;
            }

            ~ .input-group-append {
                box-shadow: 0 2px 0 $colour-indigo;
            }

            ~.graphic--nao {
                stroke: $colour-indigo;
                -webkit-transform: translate3d(-66.6%,0,0);
                transform: translate3d(-66.6%,0,0);
            }
        }
    }
}

// Form State Variations
@include form-validation-state-material("valid", $brand-success);
@include form-validation-state-material("invalid", $brand-danger);

.is-invalid {
    .form-material {
        label {
            color: $colour-red !important;
        }

        .form-control {
            transition: none;
        }

        .radio-flat-label {
            border: 1px solid $colour-red !important;
        }

        .graphic {
            &.graphic--nao {
                stroke: $colour-red;
            }
        }
    }
}
