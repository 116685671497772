//
// Main Navigation
// --------------------------------------------------

// Sidebar
.nav-main {
    margin: 0 (-$space-side);
    padding: 0;
    list-style: none;

    // Headings
    .nav-main-heading {
        padding: 30px $space-side 6px $space-side;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        opacity: 0.55;
    }

    .logout-btn {
        color: $colour-white !important;
        font-family: $font-heading;
        background: $colour-blue500 !important;
        font-size: 18px;
        padding:15px 80px !important;
        display: inline-block;
        border-radius: 10px;
        margin-top: 20px;

        > i {
            color: $colour-white;
        }
    }

    .hover-custom {
        color: $colour-white !important;
        background: $colour-blue500 !important;
        padding:15px 80px !important;
        border-radius: 5px;
        > i {
            margin-top: 50%;
            left: 1px;
            font-size: 16px;
            color: $colour-white !important;
        }

        &:hover,
        &:focus,
        &.active {
            color: $white;
            padding:9px 18px 9px 40px !important;
        }
    }

    // Default links
    a {
        position: relative;
        display: block;
        padding: 9px $space-side 9px ($space-side + 22);
        font-weight: 600;
        color: rgba($body-color, 0.9);

        @at-root .sidebar-inverse #sidebar & {
            color: rgba($body-color-light, 0.75);
        }

        &.nav-submenu {
            padding-right: 35px;
        }

        &.nav-submenu::before,
        &.nav-submenu::after {
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -11px;
            width: 20px;
            height: 20px;
            display: block;
            line-height: 20px;
            text-align: center;
            font-family: $font-family-fontawesome;
            font-size: 14px;
            transition: all $main-nav-transition;
        }

        &.nav-submenu::before {
            content: "\f104";
            opacity: 0.4;
        }

        &.nav-submenu::after {
            content: "\f103";
            opacity: 0;
            transform: rotate(90deg);
        }
    }

    // Sub menus
    ul {
        margin: 0;
        padding: 0 0 0 ($space-side + 22);
        height: 0;
        list-style: none;
        overflow: hidden;

        > li {
            opacity: 0;
            transition: all $main-nav-transition;
            transform: translateY(-15px);
        }

        .nav-main-heading {
            padding-left: 0;
            padding-right: 0;
        }

        a {
            padding: 7px 8px 7px 0;
            font-size: 13px;
            color: rgba($body-color, 0.6);

            @include hover-focus {
                background-color: transparent;
            }

            > i {
                margin-right: 10px;
            }
        }

        ul {
            padding-left: 12px;
        }
    }

    // Active sub menu
    li.open {
        > a.nav-submenu {
            color: $black;

            @at-root .sidebar-inverse #sidebar & {
                color: $white;
            }

            > i {
                color: $brand-primary;

                @at-root .sidebar-inverse #sidebar & {
                    color: $brand-primary;
                }
            }

            &::before {
                opacity: 0;
                transform: rotate(-90deg);
            }

            &::after {
                opacity: 0.4;
                transform: rotate(0);
            }
        }

        > ul {
            height: auto;
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: $body-bg-light;

            @at-root .sidebar-inverse #sidebar & {
                background-color: darken($sidebar-inverse-bg, 3%);
            }

            > li {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

// Header
.nav-main-header {
    display: none;

    @include media-breakpoint-up(lg) {
        margin: 0 -3px;
        padding: 0;
        display: inline-block;
        list-style: none;
        vertical-align: middle;

        li {
            position: relative;
            display: inline-block;
            margin: 0 3px;
        }

        // Default links
        a {
            position: relative;
            display: inline-block;
            padding: 9px 14px;
            font-weight: 600;
            line-height: 16px;
            border-radius: 3px;

            > i {
                margin-right: 8px;

                &.si {
                    position: relative;
                    top: 1px;
                }
            }

            &.nav-submenu {
                padding-right: 28px;
            }

            &.nav-submenu::before {
                position: absolute;
                top: 50%;
                right: 6px;
                margin-top: -10px;
                width: 20px;
                height: 20px;
                display: block;
                text-align: center;
                line-height: 20px;
                font-family: $font-family-fontawesome;
            }

            &.nav-submenu::before {
                content: "\f107";
                opacity: 0.5;
            }
        }

        // Sub menu list
        ul {
            position: absolute;
            top: 100%;
            left: 0;
            margin: 0;
            padding: 15px 0;
            display: none;
            z-index: $zindex-sidebar;

            li {
                display: block;
                margin: 0;
            }

            a {
                display: block;
                border-radius: 0;
                min-width: 160px;

                &.nav-submenu::before {
                    content: "\f105";
                }
            }

            ul {
                top: -15px;
                left: 100%;
            }
        }

        // Open sub menus on hover
        li:hover {
            > a.nav-submenu {
                @include border-bottom-radius(0);
            }

            > ul {
                display: block;
            }
        }

        // Flip last child’s sub menus
        > li:last-child {
            ul {
                right: 0;
                left: auto;

                a.nav-submenu::before {
                    content: "\f104";
                }

                ul {
                    right: 100%;
                    left: auto;
                }
            }
        }

        // Handle menu colors
        a {
            color: lighten($body-color, 10%);
        }

        ul a {
            color: lighten($body-color, 22%);
        }

        a:hover,
        a:focus,
        a.active,
        li.open > a.nav-submenu,
        li:hover > a.nav-submenu {
            color: $black;
            background-color: $body-bg-dark;
        }

        ul,
        > li:hover > a.nav-submenu {
            background-color: darken($body-bg-dark, 3%);
        }

        @at-root {
            .page-header-inverse #page-header &,
            .page-header-inverse.page-header-glass.page-header-scroll
                #page-header
                & {
                a {
                    color: darken($body-color-light, 9%);
                }

                ul a {
                    color: darken($body-color-light, 18%);
                }

                a:hover,
                a:focus,
                a.active,
                li.open > a.nav-submenu,
                li:hover > a.nav-submenu {
                    color: $white;
                    background-color: rgba(0, 0, 0, 0.25);
                }

                ul,
                > li:hover > a.nav-submenu {
                    background-color: $brand-dark;
                }
            }
        }

        @at-root .page-header-inverse.page-header-glass #page-header & {
            ul,
            > li:hover > a.nav-submenu {
                background-color: darken($brand-dark, 5%);
            }
        }

        // Headings
        .nav-main-heading {
            display: none;
        }

        // Hide icons from menu links
        &.nav-main-header-no-icons a > i {
            display: none;
        }
    }
}
