.coparate-timeline {
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        width: 5px;
        height: 100%;
        background: #a7a1a1;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
    }

    .timeline-content:before,
    .timeline:before {
        top: 50%;
        transform: translateY(-50%);
        content: "";
    }

    .timeline {
        width: 50%;
        height: 130px;
        padding-left: 10px;
        float: left;
        position: relative;

        &::before {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $default-timeline-color;
            border: 5px solid $default-timeline-color;
            position: absolute;
            left: auto;
            top: 60px;
            right: -10px;
        }
    }

    .timeline-content {
        display: block;
        padding: 0 150px 0 0;
        position: relative;

        &::before {
            width: 90px;
            height: 10px;
            border-top: 7px solid $default-timeline-color;
            position: absolute;
            left: auto;
            right: -10px;
            top: 60px;
        }
    }

    .date {
        display: inline-block;
        width: 120px;
        height: 120px;
        border: 10px solid $default-timeline-color;
        color: $default-timeline-color;
        text-align: center;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: auto;
        right: 80px;

        :first-child {
            margin-top: 110px;
        }

        .day {
            font-size: 33px;
            font-weight: 700;
        }

        .not-completed {
            line-height: 100px;
            font-size: 40px;
            font-weight: 700;
        }
    }
    .inner-content {
        padding: 20px 0;
        float: right;
        margin-right: 70px;
    }
    .title {
        font-size: 24px;
        font-weight: 600;
        color: $default-timeline-color;
        text-transform: uppercase;
        margin: 0 0 5px;
    }
    .description {
        font-size: 14px;
        color: #6f6f6f;
        margin: 0 0 5px;
    }

    .timeline:nth-child(2n) {
        padding: 0 0 0 100px;

        .timeline-content:before,
        .date,
        &::before {
            left: -10px;
            right: auto;
        }

        .timeline-content {
            padding: 0 0 0 150px;

            &::before {
                left: -92px;
            }
        }

        .date {
            right: 0;

            &::before {
                right: auto;
                left: 0;
                border-left: none;
                border-right: 20px solid $default-timeline-color;
                transform: rotate(-45deg);
            }
        }
    }

    .timeline:first-child {
        top: 10px;
    }

    .timeline:nth-child(even) {
        margin-top: 140px;

        .inner-content {
            padding: 20px 0;
            float: left;
            margin-left: -20px;
        }
    }

    .timeline:not(:first-child):nth-child(odd) {
        margin-top: 0px;

        .inner-content {
            padding: 20px 0;
            float: right;
            margin-right: 70px;
        }
    }

    .application_date {
        &::before {
            border-color: $application-date-color;
            background-color: $application-date-color;
        }
        .timeline-content::before {
            border-color: $application-date-color;
        }

        .date {
            border-color: $application-date-color;
            color: $application-date-color;
        }
        .title {
            color: $application-date-color;
        }
    }
    .return_receipt_date {
        &::before {
            border-color: $return-receipt-date-color;
            background-color: $return-receipt-date-color;
        }
        .timeline-content::before {
            border-color: $return-receipt-date-color;
        }

        .date {
            border-color: $return-receipt-date-color;
            color: $return-receipt-date-color;
        }
        .title {
            color: $return-receipt-date-color;
        }
    }
    .appointment_date {
        &::before {
            border-color: $appointment-date-color;
            background-color: $appointment-date-color;
        }
        .timeline-content::before {
            border-color: $appointment-date-color;
        }

        .date {
            border-color: $appointment-date-color;
            color: $appointment-date-color;
        }
        .title {
            color: $appointment-date-color;
        }
    }
    .appointment_date_provided_on {
        &::before {
            border-color: $appointment-date-provided-on-color;
            background-color: $appointment-date-provided-on-color;
        }
        .timeline-content::before {
            border-color: $appointment-date-provided-on-color;
        }

        .date {
            border-color: $appointment-date-provided-on-color;
            color: $appointment-date-provided-on-color;
        }
        .title {
            color: $appointment-date-provided-on-color;
        }
    }
    .cutover_date {
        &::before {
            border-color: $cutover-date-color;
            background-color: $cutover-date-color;
        }
        .timeline-content::before {
            border-color: $cutover-date-color;
        }

        .date {
            border-color: $cutover-date-color;
            color: $cutover-date-color;
        }
        .title {
            color: $cutover-date-color;
        }
    }
    .cutover_completed_date {
        &::before {
            border-color: $cutover-completed-date-color;
            background-color: $cutover-completed-date-color;
        }
        .timeline-content::before {
            border-color: $cutover-completed-date-color;
        }

        .date {
            border-color: $cutover-completed-date-color;
            color: $cutover-completed-date-color;
        }
        .title {
            color: $cutover-completed-date-color;
        }
    }
    .site_visit_install_date {
        &::before {
            border-color: $site-visit-install-date-color;
            background-color: $site-visit-install-date-color;
        }
        .timeline-content::before {
            border-color: $site-visit-install-date-color;
        }

        .date {
            border-color: $site-visit-install-date-color;
            color: $site-visit-install-date-color;
        }
        .title {
            color: $site-visit-install-date-color;
        }
    }
    .completion_advise_received_date {
        &::before {
            border-color: $completion-advise-received-date-color;
            background-color: $completion-advise-received-date-color;
        }
        .timeline-content::before {
            border-color: $completion-advise-received-date-color;
        }

        .date {
            border-color: $completion-advise-received-date-color;
            color: $completion-advise-received-date-color;
        }
        .title {
            color: $completion-advise-received-date-color;
        }
    }
    .activation_date {
        &::before {
            border-color: $activation-date-color;
            background-color: $activation-date-color;
        }
        .timeline-content::before {
            border-color: $activation-date-color;
        }

        .date {
            border-color: $activation-date-color;
            color: $activation-date-color;
        }
        .title {
            color: $activation-date-color;
        }
    }
    .incompleted-stage {
        &::before {
            border-color: $incompleted-stage-color;
            background-color: $incompleted-stage-color;
        }
        .timeline-content::before {
            border-color: $incompleted-stage-color;
        }

        .date {
            border-color: $incompleted-stage-color;
            color: $incompleted-stage-color;
        }
        .title {
            color: $incompleted-stage-color;
        }
    }

    @media only screen and (max-width: 414px) {
        .timeline:nth-child(odd) {
            height: 150px !important;
            .date {
                right: 68px;
            }
            .timeline-content::before {
                width: 80px;
            }
            .inner-content {
                width: 140px;
                padding: 0 !important;
                float: left !important;
                margin-right: 20px !important;
                margin-top: 125px;
                .description {
                    display: none;
                }
                .title {
                    text-align: left;
                }
            }
        }

        .timeline:nth-child(even) {
            height: 150px !important;
            .date {
                left: -22px;
            }
            .timeline-content::before {
                width: 80px;
            }
            .inner-content {
                width: 140px;
                padding: 0 !important;
                float: right !important;
                margin-right: 56px !important;
                margin-top: 125px;
                .description {
                    display: none;
                }
                .title {
                    text-align: right;
                }
            }
        }
    }

    @media only screen and (max-width: 375px) {
        .timeline:nth-child(odd) {
            height: 150px !important;
            .date {
                right: 50px;
            }
            .timeline-content::before {
                width: 70px;
            }
            .inner-content {
                width: 140px;
                padding: 0 !important;
                float: left !important;
                margin-right: 20px !important;
                margin-top: 125px;
                .description {
                    display: none;
                }
                .title {
                    text-align: left;
                }
            }
        }

        .timeline:nth-child(even) {
            height: 150px !important;
            .date {
                left: -50px;
            }
            .timeline-content::before {
                width: 50px;
            }
            .inner-content {
                width: 140px;
                padding: 0 !important;
                float: right !important;
                margin-right: 83px !important;
                margin-top: 125px;
                .description {
                    display: none;
                }
                .title {
                    text-align: right;
                }
            }
        }
    }
    @media only screen and (max-width: 280px) {
        .date {
            width: 75px;
            height: 75px;
            border-width: 2px;
        }
        .day {
            padding-top: 10px;
        }
        .year {
            display: none;
        }
        .month {
            display: none;
        }
        br {
            display: none;
        }
        .timeline {
            &::before {
                top: 38px !important;
            }
            &:nth-child(odd) {
                height: 150px !important;
                .date {
                    right: 50px;
                }
                .timeline-content::before {
                    width: 45px !important;
                    right: 6px !important;
                    top: 40px !important;
                }
                .inner-content {
                    width: 140px;
                    padding: 0 !important;
                    float: left !important;
                    margin-right: 20px !important;
                    margin-top: 125px;
                    .description {
                        display: none;
                    }
                    .title {
                        text-align: left;
                    }
                }
            }
        }

        .timeline:nth-child(even) {
            height: 150px !important;
            .date {
                left: -50px;
            }
            .timeline-content::before {
                width: 45px !important;
                top: 40px !important;
            }
            .inner-content {
                width: 140px;
                padding: 0 !important;
                float: right !important;
                margin-right: 80px !important;
                margin-top: 125px;
                .description {
                    display: none;
                }
                .title {
                    text-align: right;
                }
            }
        }
    }
}
