//
// Breadcrumb
// --------------------------------------------------

.breadcrumb-item {
    + .breadcrumb-item::before {
        font-family: $font-family-fontawesome;
        font-weight: 900;
        content: '\f105';
    }
}