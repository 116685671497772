//
// Grid
// --------------------------------------------------

// Tiny grid gutters
.row.gutters-tiny {
    margin-right: (-($space-row-gutter-tiny * 0.5));
    margin-left: (-($space-row-gutter-tiny * 0.5));

    > .col,
    > [class*="col-"] {
        padding-left: ($space-row-gutter-tiny * 0.5);
        padding-right: ($space-row-gutter-tiny * 0.5);
    }

    .push,
    .block {
        margin-bottom: $space-row-gutter-tiny;
    }
}

// Equal height of elements inside columns
.row.row-deck > div {
    display: flex;
    align-items: stretch;

    > .block {
        min-width: 100%;
    }
}
