//
// Nestable2
//
// Overwrite/Extend styles
// --------------------------------------------------

.dd,
.dd-item,
.dd-empty,
.dd-placeholder {
    font-size: 13px;
}

.dd-handle {
    height: 2.5rem;
    padding: .5rem .75rem;
    color: $body-color;
    background: $body-bg-light;
    border-color: $body-bg-dark;

    &:hover {
        color: $body-color-dark;
    }
}

.dd-item > button {
    height: 1.625rem;
}

.dd-empty,
.dd-placeholder {
    border-color: $brand-darker;
    background: $brand-lighter;
    opacity: .25;
}