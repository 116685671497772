//
// Radio Flat
// --------------------------------------------------

.radio-flat {
	visibility: hidden;
	height: 0;
	width: 0;
}

.radio-flat-label {
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	background-color: $colour-spearmint;
	color: $colour-indigo !important;
	padding: 0px 10px;
	border-radius: 3px;
	height: calc(2.028572em + 0.8571428rem + 2px);
    line-height: calc(2.028572em + 0.8571428rem + 2px);
}

.radio-flat:checked + .radio-flat-label{
	background-color: $colour-indigo;
	color: $colour-white !important;

	h2 {
		color: $colour-white !important;
	}
}
